define('spellbinder2/controllers/characters/create-character', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      inject = Ember.inject;
  exports.default = Controller.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    createCharacterModalIsLoading: false,

    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    user: alias('model.user'),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      formSubmitted: function formSubmitted(newCharacter) {
        console.log("formSubmitted create-character");
        this.transitionToRoute('characters.character.spells', get(newCharacter, 'id'));
      }
    }
  });
});