define('spellbinder2/routes/characters/character/spells', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Route.extend({

    partialLoad: true,

    model: function model() {
      var characterModel = this.modelFor('characters.character');

      var allSpellsPromise = null;
      if (get(this, "partialLoad")) {
        set(this, 'partialLoad', false);
        allSpellsPromise = get(this, 'store').query('spell', { dummyValue: true });
        var _this = this;
        Ember.run.later(function () {
          _this.refresh();
        }, 250);
      } else {
        allSpellsPromise = get(this, 'store').findAll('spell');
      }

      return Ember.RSVP.hash({
        allSpells: allSpellsPromise,
        character: characterModel.character,
        characterSpells: get(characterModel.character, 'spells')
      }).catch(function (error) {
        console.error(error);
      });
    }
  });
});