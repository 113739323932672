define('spellbinder2/routes/characters', ['exports', 'spellbinder2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get;
  exports.default = Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        user: this.getUser(),
        characters: this.getCharacters()
      }).catch(function (error) {
        console.error(error);
      });
    },
    afterModel: function afterModel(model) {
      var c = model.characters;
      if (c === null || c === undefined || get(c, 'length') <= 0) {
        return this.transitionTo('characters.create-character');
      }
    },


    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    getCharacters: function getCharacters() {
      return this.getUser().then(function (user) {
        if (user === null || user === undefined) {
          return null;
        }

        return get(user, 'characters');
      });
    },
    getUser: function getUser() {
      var _this = this;

      var uid = get(this, 'session.currentUser.uid');

      if (uid === null || uid === undefined) {
        throw new Error('No UID defined');
      }

      return get(this, 'store').findRecord('user', uid).catch(function (error) {
        if (_this._isNoRecordError(error, uid)) {
          return null;
        } else {
          console.error(error);
        }
      });
    },
    _isNoRecordError: function _isNoRecordError(error, id) {
      var path = _environment.default.firebase.databaseURL + '/users/' + id;
      var expectedMsg = 'no record was found at ' + path;
      return error.message === expectedMsg;
    }
  });
});