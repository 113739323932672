define('spellbinder2/routes/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      set = Ember.set,
      inject = Ember.inject;
  exports.default = Route.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    session: inject.service(),

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    beforeModel: function beforeModel() {
      return get(this, 'session').fetch().catch(function (error) {
        // console.error(error);
      });
    },
    redirect: function redirect() {
      if (get(this, 'session.isAuthenticated')) {
        this.transitionTo('characters');
      }
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      signIn: function signIn(provider) {
        var _this = this;

        get(this, 'session').open('firebase', { provider: "google" }).then(function (data) {
          _this.transitionTo('characters');
        }).catch(function (error) {
          console.error(error);
        });
      },
      signOut: function signOut() {
        this.get('session').close();
      }
    }
  });
});