define('spellbinder2/routes/characters/character', ['exports', 'spellbinder2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Route.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    router: inject.service(''),

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    model: function model(params) {
      var _this = this;

      return Ember.RSVP.hash({
        character: this.getCharacter(params.character_id),
        user: this.getUser(),
        characters: this.getCharacters()
      }).catch(function (error) {
        if (_this._isNoRecordError(error, params.character_id)) {
          _this.transitionTo('characters');
        } else {
          console.error(error);
        }
      });
    },
    afterModel: function afterModel() {
      if (get(this, 'route.currentRouteName') === 'character.character.index') {
        this.transitionTo('characters.character.spells');
      }
    },


    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    getCharacter: function getCharacter(character_id) {
      return get(this, 'store').findRecord('character', character_id);
    },
    getCharacters: function getCharacters() {
      return this.getUser().then(function (user) {
        if (user === null || user === undefined) {
          return null;
        }

        return get(user, 'characters').sortBy('name');
      });
    },
    getUser: function getUser() {
      var _this2 = this;

      var uid = get(this, 'session.currentUser.uid');

      if (uid === null || uid === undefined) {
        throw new Error('No UID defined');
      }

      return get(this, 'store').findRecord('user', uid).catch(function (error) {
        if (_this2._isNoRecordError(error, uid)) {
          return null;
        } else {
          console.error(error);
        }
      });
    },
    _isNoRecordError: function _isNoRecordError(error, id) {
      var path = _environment.default.firebase.databaseURL + '/characters/' + id;
      var expectedMsg = 'no record was found at ' + path;
      return error.message === expectedMsg;
    }
  });
});