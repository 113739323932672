define('spellbinder2/components/modal-edit-spell-slots', ['exports', 'spellbinder2/components/modal-base'], function (exports, _modalBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = _modalBase.default.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    store: inject.service(''),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-modal-editSpellSlots"],
    selectedLevel: null,

    slotsOptions: [{
      name: "Level 1",
      value: 1
    }, {
      name: "Level 2",
      value: 2
    }, {
      name: "Level 3",
      value: 3
    }, {
      name: "Level 4",
      value: 4
    }, {
      name: "Level 5",
      value: 5
    }, {
      name: "Level 6",
      value: 6
    }, {
      name: "Level 7",
      value: 7
    }, {
      name: "Level 8",
      value: 8
    }, {
      name: "Level 9",
      value: 9
    }],

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'keyboardPriority', 2);
    },


    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    showInputNumber: computed('selectedLevel', function () {
      return _get(this, 'selectedLevel') !== null && _get(this, 'selectedLevel') !== undefined;
    }),

    selectedLevelModel: computed('selectedLevel', 'slots', {
      get: function get() {
        var s = _get(this, 'slots');
        var sl = _get(this, 'selectedLevel');

        if (s === null || s === undefined) {
          return null;
        }

        if (sl === null || sl === undefined) {
          return null;
        }

        var selected = s.findBy('level', sl.value);

        if (selected === null || selected === undefined) {
          return null;
        }

        return selected;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    numberOfSlots: computed('selectedLevelModel', {
      get: function get() {
        var slm = _get(this, 'selectedLevelModel');

        if (slm === null || slm === undefined) {
          return 0;
        }

        return _get(slm, 'maximum');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedConfirmEdit: function clickedConfirmEdit() {
        var _this = this;

        set(this, 'isLoading', true);

        var sl = _get(this, 'selectedLevel');
        var slm = _get(this, 'selectedLevelModel');
        var nos = _get(this, 'numberOfSlots');
        var c = _get(this, 'character');

        if (slm === null || slm === undefined) {
          var ns = _get(this, 'store').createRecord('slot', {
            level: sl.value,
            maximum: nos,
            filled: 0
          });

          _get(c, 'slots').addObject(ns);

          c.save().then(function () {
            set(_this, 'isLoading', false);
            _get(_this, 'clickedConfirmEdit')();
          }).catch(function (error) {
            console.error(error);
          });
        } else {
          set(slm, 'maximum', nos);
          if (_get(slm, 'filled') > nos) {
            set(slm, 'filled', nos);
          }
          slm.save().then(function () {
            set(_this, 'isLoading', false);
            _get(_this, 'clickedConfirmEdit')();
          });
        }
      },
      clickedCancelEdit: function clickedCancelEdit() {
        _get(this, 'clickedCancelEdit')();
      },
      updatedSelectValue: function updatedSelectValue(value) {
        set(this, 'selectedLevel', value);
      },
      updatedNumberOfSlots: function updatedNumberOfSlots(number) {
        set(this, 'numberOfSlots', number);
      }
    }
  });
});