define('spellbinder2/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Controller.extend({

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      formSubmitted: function formSubmitted() {
        this.transitionToRoute('characters');
      }
    }
  });
});