define('spellbinder2/components/spell-slots-manager', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-spellSlotsManager"],
    classNameBindings: ["isActive"],
    isActive: false,
    showModalEditSpellSlots: false,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.get('EventBus').subscribe('clickedClosedSpellSlotManager', this, 'onClickedClosedSpellSlotManager');
      set(this, 'keyboardPriority', 1);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, 'isActive')) {
        set(this, 'keyboardActivated', true);
      } else {
        set(this, 'keyboardActivated', false);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('EventBus').unsubscribe('clickedClosedSpellSlotManager');
    },


    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    slots: alias('character.slots'),

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    slotsOrganized: computed('slots', function () {
      return get(this, 'slots').sortBy('level');
    }),

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    onClickedClosedSpellSlotManager: function onClickedClosedSpellSlotManager() {
      set(this, 'isActive', false);
    },


    onEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function (event) {
      if (get(this, 'isActive')) {
        get(this, 'clickedCloseModal')();
      }
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedOverlay: function clickedOverlay() {
        get(this, 'clickedCloseModal')();
      },
      clickedEditSpellSlot: function clickedEditSpellSlot() {
        set(this, 'showModalEditSpellSlots', true);
        get(this, 'EventBus').publish('updateNavButtonState', 'editingSpellSlots');
      },
      clickedConfirmEdit: function clickedConfirmEdit() {
        set(this, 'showModalEditSpellSlots', false);
        get(this, 'EventBus').publish('updateNavButtonState', 'closeSpellSlotManager');
      },
      clickedCancelEdit: function clickedCancelEdit() {
        set(this, 'showModalEditSpellSlots', false);
        get(this, 'EventBus').publish('updateNavButtonState', 'closeSpellSlotManager');
      },
      clickedRefreshSlots: function clickedRefreshSlots() {
        get(this, 'slots').forEach(function (level) {
          set(level, 'filled', 0);
        });

        get(this, 'character').save();
      }
    }
  });
});