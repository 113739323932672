define('spellbinder2/components/scroll-view', ['exports', 'spellbinder2/mixins/scrolling'], function (exports, _scrolling) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend(_scrolling.default, {

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-scrollView"],
    classNameBindings: ["isDisabled"],

    isDisabled: false,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    didInsertElement: function didInsertElement() {
      this.bindScrolling();

      // Ember.run.next(this, () => {
      if (typeof get(this, 'didScroll') === "function") {
        get(this, 'didScroll')(get(this, 'element'));
      }
      // });
    },

    willDestoryElement: function willDestoryElement() {
      this.unbindScrolling();
    },

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    scrolled: function scrolled() {
      // Ember.run.next(this, () => {
      if (typeof get(this, 'didScroll') === "function") {
        get(this, 'didScroll')(get(this, 'element'));
      }
      // });
    }
  });
});