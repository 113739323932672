define('spellbinder2/routes/application', ['exports', 'spellbinder2/config/environment', 'ember-cordova-events/utils/subscribe'], function (exports, _environment, _subscribe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      service = Ember.inject.service;
  exports.default = Ember.Route.extend({

    cordovaEvents: service('ember-cordova/events'),
    platform: service('ember-cordova/platform'),

    deviceReady: (0, _subscribe.default)('cordovaEvents.deviceready', function () {
      // Configure ads
      var admobid = {};
      if (/(android)/i.test(navigator.userAgent)) {
        // for android & amazon-fireos
        admobid = {
          banner: 'ca-app-pub-2794000423191888/8278907639', // or DFP format "/6253334/dfp_example_ad"
          interstitial: 'ca-app-pub-2794000423191888/9400417611'
        };
      } else if (/(ipod|iphone|ipad)/i.test(navigator.userAgent)) {
        // for ios
        admobid = {
          banner: 'ca-app-pub-2794000423191888/4650514197', // or DFP format "/6253334/dfp_example_ad"
          interstitial: 'ca-app-pub-2794000423191888/4036927211'
        };
      }
      window.plugins.AdMob.setOptions({
        publisherId: admobid.banner,
        interstitialAdId: admobid.interstitial,
        bannerAtTop: false, // set to true, to put banner at top
        overlap: false, // set to true, to allow banner overlap webview
        offsetTopBar: false, // set to true to avoid ios7 status bar overlap
        isTesting: _environment.default.testingAds, // Set this correctly!
        autoShow: true // auto show interstitial ad when loaded
      });
      // display the banner at startup
      window.plugins.AdMob.createBannerView();

      // create interstitial ad
      window.plugins.AdMob.createInterstitialView();
      window.plugins.AdMob.showInterstitialAd(true, function () {}, function (e) {
        alert(JSON.stringify(e));
      });

      // Set up platform specific code
      var platform = get(this, 'platform');
      console.log('Loading ios specific code');

      if (platform.get('isIOS')) {
        console.log("setting css");
        Ember.$('body').addClass('platform-iOS');

        // Set version number on the body
        // Get version name manually because platform.get('version') isn't working
        var userAgent = window.navigator.userAgent;
        // check for iPhone OS
        var versionString = userAgent.match(/iPhone OS [0-9]*/i);
        // double check we found a match
        if (versionString !== null && versionString.length > 0) {
          var version = versionString[0].substr(10);
          Ember.$('body').addClass('platform-iOS-' + version);
        }
        // check for iPad OS
        versionString = userAgent.match(/iPad; CPU OS [0-9]*/i);
        // double check we found a match
        if (versionString !== null && versionString.length > 0) {
          var _version = versionString[0].substr(13);
          Ember.$('body').addClass('platform-iOS-' + _version);
        }
      }
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      accessDenied: function accessDenied() {
        this.transitionTo('login');
      }
    }
  });
});