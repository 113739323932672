define("spellbinder2/components/button-navigation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-buttonNavigation"],
    classNameBindings: ["state"],
    attributeBindings: ["style"],
    style: null,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.get('EventBus').subscribe('updateNavButtonState', this, 'onUpdateNavButtonState');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('EventBus').unsubscribe('updateNavButtonState');
    },


    // didReceiveAttrs() {
    //   if (get(this, 'state') === 'closePanelChooseCharacters') {
    //     const panel = Ember.$('.container-panel-chooseCharacter');
    //     const height = `${(panel.height() - 84 / 2) / 16}`;
    //     const style = `translate3d(-50%,${height}rem,0px)`;
    //     const el = get(this, 'element');
    //     Ember.$(el).css({
    //       "transform": style,
    //       "-webkit-transform": style
    //     });
    //   } else {
    //     const el = get(this, 'element');
    //     Ember.$(el).css({
    //       "transform": "",
    //       "-webkit-transform": ""
    //     });
    //   }
    // },

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    onUpdateNavButtonState: function onUpdateNavButtonState(state) {
      get(this, 'onUpdateNavButtonState')(state);
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    click: function click() {
      get(this, 'clickedButtonNavigation')();
    }
  });
});