define("spellbinder2/components/list-item-spell", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "li",
    classNames: ["item-spellsList"],

    isOnSpellList: computed('spell', 'characterSpells.[]', function () {
      return get(this, 'characterSpells').isAny('id', get(this, 'spell.id'));
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedAddSpell: function clickedAddSpell() {
        var character = get(this, 'character');
        var spells = get(character, 'spells');
        var spell = get(this, 'spell');

        spells.addObject(spell);

        character.save();
      },
      clickedRemoveSpell: function clickedRemoveSpell() {
        var character = get(this, 'character');
        var spells = get(character, 'spells');
        var spell = get(this, 'spell');

        var oldSpell = spells.findBy('id', get(spell, 'id'));

        spells.removeObject(oldSpell);

        character.save();
      }
    }
  });
});