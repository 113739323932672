define("spellbinder2/components/segmented-filter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "ol",
    classNames: ["container-segmentedFilter"],
    classNameBindings: ["countClass"],

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    countClass: computed('segments.length', function () {
      return "count-" + get(this, 'segments.length');
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedSegment: function clickedSegment(segment) {
        set(this, 'activeSegment', segment);
        get(this, 'clickedSegment')(segment);
      }
    }
  });
});