define('spellbinder2/routes/characters/create-character', ['exports', 'spellbinder2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get;
  exports.default = Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        user: this.getUser()
      }).catch(function (error) {
        console.error(error);
      });
    },


    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    getUser: function getUser() {
      var _this = this;

      var uid = get(this, 'session.currentUser.uid');

      if (uid === null || uid === undefined) {
        throw new Error('No UID defined');
      }

      return get(this, 'store').findRecord('user', uid).catch(function (error) {
        if (_this._isNoRecordError(error, uid)) {
          return null;
        } else {
          console.error(error);
        }
      });
    },
    _isNoRecordError: function _isNoRecordError(error, id) {
      var path = _environment.default.firebase.databaseURL + '/users/' + id;
      var expectedMsg = 'no record was found at ' + path;
      return error.message === expectedMsg;
    }
  });
});