define('spellbinder2/components/spell-description', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({

    classNames: ["container-description-spell"],

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    didRender: function didRender() {
      this._super.apply(this, arguments);

      var tables = Ember.$('.container-description-spell table');

      if (tables !== null && tables !== undefined && get(tables, 'length') !== 0) {
        tables.each(function () {

          if (this.offsetWidth > 525) {
            $(this).tableit();
            $(this).addClass('tableit-force');
          }
        });
      }
    }
  });
});