define('spellbinder2/routes/characters/character/spells/spell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Route.extend({
    model: function model(params) {

      var characterModel = this.modelFor('characters.character');
      var spellsModel = this.modelFor('characters.character.spells');

      return Ember.RSVP.hash({
        spell: get(this, 'store').findRecord('spell', params.spell_id),
        characterSpells: spellsModel.characterSpells,
        character: characterModel.character
      });
    }
  });
});