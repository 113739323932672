define('spellbinder2/controllers/characters/character', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      _get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      inject = Ember.inject;
  exports.default = Controller.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    router: inject.service(''),
    charactersController: inject.controller('characters'),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    showModalSettings: false,
    showPanelChooseCharacter: false,
    showModalCreateCharacter: false,
    showSpellSlotManager: false,

    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    character: alias('model.character'),
    characters: alias('model.characters'),
    user: alias('model.user'),
    charactersModel: alias('charactersController.model'),

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    navButtonState: computed('router.currentRouteName', {
      get: function get() {

        if (_get(this, 'router.currentRouteName') === 'characters.character.spells.spell' && _get(this, 'media.isMobile')) {
          return 'backToSpells';
        }

        var cr = _get(this, 'router.currentRouteName');
        var parts = cr.split(".");

        if (parts[1] === 'character') {
          return 'openChooseCharacter';
        }

        return 'hidden';
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    _createNewCharacter: function _createNewCharacter(characterName) {
      return _get(this, 'store').createRecord('character', {
        name: characterName
      });
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedSlots: function clickedSlots() {
        set(this, 'navButtonState', 'closeSpellSlotManager');
        set(this, 'showSpellSlotManager', true);
      },
      clickedUser: function clickedUser() {
        set(this, 'navButtonState', 'closeSettings');
        set(this, 'showModalSettings', true);
      },
      clikedCharacter: function clikedCharacter() {
        set(this, 'showPanelChooseCharacter', false);
        set(this, 'navButtonState', 'openChooseCharacter');
      },
      clickedClosePanelCharacters: function clickedClosePanelCharacters() {
        set(this, 'showPanelChooseCharacter', false);
        set(this, 'navButtonState', 'openChooseCharacter');
      },
      onUpdateNavButtonState: function onUpdateNavButtonState(state) {
        set(this, 'navButtonState', state);
      },
      clickedButtonNavigation: function clickedButtonNavigation() {
        var _this = this;

        switch (_get(this, 'navButtonState')) {
          case 'backToSpells':
            // set(this, 'spellIsSelected', false);
            _get(this, 'EventBus').publish('clickedReturnToSpells');
            set(this, 'navButtonState', 'openChooseCharacter');
            Ember.run.later(this, function () {
              _this.transitionToRoute('characters.character.spells');
            }, 400);
            break;
          case 'openChooseCharacter':
            set(this, 'showPanelChooseCharacter', true);
            // set(this, 'navButtonState', 'hiddenWhileChooseCharacters');
            break;
          case 'closeSettings':
            set(this, 'showModalSettings', false);
            set(this, 'navButtonState', 'openChooseCharacter');
            break;
          case 'closeCreateCharacter':
            set(this, 'showModalCreateCharacter', false);
            set(this, 'navButtonState', 'closePanelChooseCharacters');
            break;
          case 'closeSpellSlotManager':
            _get(this, 'EventBus').publish('clickedClosedSpellSlotManager');

            if (_get(this, 'media.isMobile') && _get(this, 'router.currentRouteName') === 'characters.character.spells.spell') {
              set(this, 'navButtonState', 'backToSpells');
            } else {
              set(this, 'navButtonState', 'openChooseCharacter');
            }
            break;
          case 'closeEditCharacter':
            _get(this, 'EventBus').publish('clickedCloseEditCharacter');
            set(this, 'navButtonState', 'openChooseCharacter');
            break;
          default:
            return true;
        }
      },
      signOut: function signOut() {
        var _this2 = this;

        _get(this, 'session').close().then(function () {
          set(_this2, 'showModalSettings', false);
          _this2.transitionToRoute('login');
        });
      },
      clickedAddNewCharacter: function clickedAddNewCharacter() {
        set(this, 'showModalCreateCharacter', true);
        set(this, 'navButtonState', 'closeCreateCharacter');
      },
      newCharacterFormSubmitted: function newCharacterFormSubmitted(newCharacter) {
        set(this, 'showModalCreateCharacter', false);
        set(this, 'navButtonState', 'openChooseCharacter');
        set(this, 'showPanelChooseCharacter', false);
        this.transitionToRoute('characters.character.spells', _get(newCharacter, 'id'));
      },
      deletedCharacter: function deletedCharacter(character, characters) {
        set(this, 'navButtonState', 'openChooseCharacter');
        set(this, 'showPanelChooseCharacter', false);

        console.log(characters);

        set(this, 'characters', characters);
        set(this, 'charactersController.model.characters', characters);

        if (_get(character, 'id') === _get(this, 'character.id')) {
          if (characters === undefined || characters === null || _get(characters, 'length') <= 0) {
            this.transitionToRoute('characters.create-character');
          } else {
            this.transitionToRoute('characters');
          }
        }
      },
      clickedCloseSettings: function clickedCloseSettings() {
        set(this, 'showModalSettings', false);
        set(this, 'navButtonState', 'openChooseCharacter');
      },
      clickedCloseModalCreateCharacter: function clickedCloseModalCreateCharacter() {
        set(this, 'showModalCreateCharacter', false);
        set(this, 'navButtonState', 'closePanelChooseCharacters');
      },
      clickedCloseSpellSlotManager: function clickedCloseSpellSlotManager() {
        _get(this, 'EventBus').publish('clickedClosedSpellSlotManager');

        if (_get(this, 'media.isMobile') && _get(this, 'router.currentRouteName') === 'characters.character.spells.spell') {
          set(this, 'navButtonState', 'backToSpells');
        } else {
          set(this, 'navButtonState', 'openChooseCharacter');
        }
      }
    }
  });
});