define('spellbinder2/controllers/characters/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      inject = Ember.inject;
  exports.default = Controller.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    router: inject.service(''),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    createCharacterModalIsLoading: false,

    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    user: alias('model.user'),
    modelCharacters: alias('model.characters'),

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    characters: computed('modelCharacters.@each.{isDeleted,isLoaded}', function () {
      var c = get(this, 'modelCharacters');

      if (c === null || c === undefined) {
        return null;
      }

      return c.sortBy('name');
    }),

    // characterIsSelected: computed('router.currentRouteName', {
    //   get() {
    //     const cr = get(this, 'router.currentRouteName');
    //     const parts = cr.split(".");
    //     return parts[1] === 'character';
    //   },
    //
    //   set(key, value) {
    //     return value;
    //   }
    // }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedChooseCharacter: function clickedChooseCharacter(character) {
        this.transitionToRoute('characters.character.spells', get(character, 'id'));
        set(this, 'showModalChooseCharacter', false);
        set(this, 'navButtonState', 'openSettings');
      }
    }
  });
});