define('spellbinder2/components/container-filters-spells', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "",
    // classNames: ["container-container-filters-spells"],
    // classNameBindings: ['showFilters'],
    segments: [{
      name: "All Spells",
      value: "all_spells"
    }, {
      name: "My Spells",
      value: "user_spells"
    }],
    showFilters: false,
    showSegmentedFilters: true,

    searchValue: "",

    levels: [{
      name: 0,
      isActive: false
    }, {
      name: 1,
      isActive: false
    }, {
      name: 2,
      isActive: false
    }, {
      name: 3,
      isActive: false
    }, {
      name: 4,
      isActive: false
    }, {
      name: 5,
      isActive: false
    }, {
      name: 6,
      isActive: false
    }, {
      name: 7,
      isActive: false
    }, {
      name: 8,
      isActive: false
    }, {
      name: 9,
      isActive: false
    }],

    selectedClass: null,
    selectedSchool: null,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    // init() {
    //   this._super(...arguments);
    //   set(this, 'keyboardPriority', 1);
    // },

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    showButtonClearFilters: computed('searchValue', 'levels.@each.isActive', 'selectedSchool', 'selectedClass', function () {
      var hasSearch = get(this, 'searchValue') !== "";
      var levelsActive = get(this, 'levels').isAny('isActive', true);
      var hasClassSelected = get(this, 'selectedClass') !== null;
      var hasSchoolSelected = get(this, 'selectedSchool') !== null;

      return hasSearch || levelsActive || hasClassSelected || hasSchoolSelected;
    }),

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    onEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function (event) {
      if (get(this, 'showFilters')) {
        this.toggleProperty('showFilters');
        this.toggleProperty('showSegmentedFilters');
        this.toggleProperty('keyboardActivated');
      }
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedToggleFilters: function clickedToggleFilters() {
        this.toggleProperty('showFilters');
        this.toggleProperty('showSegmentedFilters');
        this.toggleProperty('keyboardActivated');
      },
      clickedClearFilters: function clickedClearFilters() {

        get(this, 'EventBus').publish('clearSearchInput');

        set(this, 'searchValue', "");
        set(this, 'selectedClass', null);
        set(this, 'selectedSchool', null);

        get(this, 'levels').forEach(function (level) {
          set(level, 'isActive', false);
        });
      },
      updatedSearchValue: function updatedSearchValue(value) {
        set(this, 'searchValue', value);
      }
    }
  });
});