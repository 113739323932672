define('spellbinder2/controllers/characters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      inject = Ember.inject;
  exports.default = Controller.extend({});
});