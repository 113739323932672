define('spellbinder2/instance-initializers/ember-do-forms/initializer', ['exports', 'ember-do-forms/instance-initializers/ember-do-forms/initializer'], function (exports, _initializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _initializer.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _initializer.initialize;
    }
  });
});