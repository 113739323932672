define('spellbinder2/models/character', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      computed = _Ember.computed;
  exports.default = _emberData.default.Model.extend({
    avatarURL: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    spells: _emberData.default.hasMany('spell'),
    user: _emberData.default.belongsTo('user'),
    slots: _emberData.default.hasMany('slot', { async: false, inverse: null })

    // avatarURL: computed('', function() {
    //   return "https://78.media.tumblr.com/e2c3e5045a55e75b49d79d7e1de5337b/tumblr_ojk408UNZj1viqluio1_1280.jpg";
    // }),
  });
});