define('spellbinder2/components/modal-base', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-modal"],
    classNameBindings: ["isActive", "noFooter"],

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, 'isActive')) {
        set(this, 'keyboardActivated', true);
      } else {
        set(this, 'keyboardActivated', false);
      }
    },


    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    onEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function (event) {
      if (get(this, 'isActive')) {
        get(this, 'clickedCloseModal')();
      }
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedOverlay: function clickedOverlay() {
        if (get(this, 'clickOverlayToClose')) {
          if (typeof get(this, 'clickedCloseModal') === "function") {
            get(this, 'clickedCloseModal')();
          }
        }
      }
    }
  });
});