define('spellbinder2/components/level-spell-slots', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-levelSpellSlots"],

    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    level: alias('slotLevel.level'),
    maximum: alias('slotLevel.maximum'),
    filled: alias('slotLevel.filled'),

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    filledArray: computed('filled', function () {
      var len = get(this, 'filled');
      return this._generateArray(len);
    }),

    unfilledArray: computed('filled', 'maximum', function () {
      var len = get(this, 'maximum') - get(this, 'filled');
      return this._generateArray(len);
    }),

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    _generateArray: function _generateArray(len) {
      var arr = Ember.A([]);

      for (var i = 0; i < len; i++) {
        arr.addObject(i + 1);
      }

      return arr;
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedButtonSpellSlot: function clickedButtonSpellSlot(isActive) {
        var c = get(this, 'character');
        var sl = get(this, 'slotLevel');

        if (isActive) {
          sl.decrementProperty('filled');
        } else {
          sl.incrementProperty('filled');
        }

        c.save();
      }
    }
  });
});