define('spellbinder2/components/list-spells', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "",
    isLoading: true,
    isLazyLoading: true,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(this, function () {
        set(_this, 'isLoading', false);
      });
    },


    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    spellsLength: computed('spells.[]', function () {
      var s = get(this, 'spells');

      if (s === undefined || s === null) {
        return null;
      }

      return get(s, 'length');
    }),

    noSpells: computed('spells.[]', function () {
      return get(this, 'spells') === null || get(this, 'spells') === undefined || get(this, 'spells.length') <= 0;
    }),

    isUserList: computed('list', function () {
      return get(this, 'list') === "user_spells";
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      didScroll: function didScroll(el) {
        var _this2 = this;

        // If at the bottom of the scroll view
        if (el.offsetHeight + el.scrollTop >= el.scrollHeight / 2) {

          if (get(this, 'spellsLength') < get(this, 'spellsMax')) {
            set(this, 'isLazyLoading', true);
          }

          get(this, 'updateList')();

          Ember.run.next(this, function () {
            if (get(_this2, 'spellsLength') >= get(_this2, 'spellsMax')) {
              set(_this2, 'isLazyLoading', false);
            }
          });
        }
      },
      clickedEmptyStateButton: function clickedEmptyStateButton() {
        get(this, 'clickedEmptyStateButton')({ value: "all_spells" });
      }
    }
  });
});