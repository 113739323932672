define('spellbinder2/router', ['exports', 'spellbinder2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login', { path: "/" });
    this.authenticatedRoute('characters', function () {
      this.route('create-character');
      this.route('character', { path: "/:character_id" }, function () {
        this.route('spells', function () {
          this.route('spell', { path: "/:spell_id" });
        });
      });
    });
    this.route('register');
  });

  exports.default = Router;
});