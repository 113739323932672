define('spellbinder2/components/modal-edit-character', ['exports', 'spellbinder2/components/modal-base'], function (exports, _modalBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = _modalBase.default.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-modal-editCharacter"],

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'keyboardPriority', 2);
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedOpenRenameCharacter: function clickedOpenRenameCharacter() {
        get(this, 'clickedOpenRenameCharacter')();
      },
      clickedOpenChangeImage: function clickedOpenChangeImage() {
        get(this, 'clickedOpenChangeImage')();
      },
      clickedDeleteCharacter: function clickedDeleteCharacter() {
        get(this, 'clickedDeleteCharacter')();
      }
    }
  });
});