define("spellbinder2/components/button-toggle-filters", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "button",
    classNames: ["container-button-toggleFilters", "button", "fill-yellowMedium", "text-blackDark"],
    classNameBindings: ["isActive"],
    isActive: false,

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    click: function click() {
      // this.toggleProperty('isActive');
      get(this, 'clickedToggleFilters')();
    }
  });
});