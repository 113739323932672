define('spellbinder2/initializers/event-bus', ['exports', 'spellbinder2/services/event-bus'], function (exports, _eventBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var eventBus = _eventBus.default.create();

    application.register('event-bus:current', eventBus, {
      instantiate: false
    });

    application.inject('service', 'EventBus', 'event-bus:current');
    application.inject('route', 'EventBus', 'event-bus:current');
    application.inject('component', 'EventBus', 'event-bus:current');
    application.inject('controller', 'EventBus', 'event-bus:current');
  }

  exports.default = {
    name: 'event-bus',
    initialize: initialize
  };
});