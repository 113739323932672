define("spellbinder2/components/input-select", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-input-select"],
    classNameBindings: ["classes", "hasValue"],

    value: null,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(this, function () {
        get(_this, 'updatedValue')(get(_this, 'value'));
      });
    },


    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    hasValue: computed('value', function () {
      return get(this, 'value') !== null && get(this, 'value') !== undefined;
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      updatedValue: function updatedValue(value) {
        set(this, 'value', value);
        get(this, 'updatedValue')(value);
      }
    }
  });
});