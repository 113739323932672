define('spellbinder2/services/ember-do-forms/config', ['exports', 'ember-do-forms/services/ember-do-forms/config'], function (exports, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _config.default;
    }
  });
});