define("spellbinder2/components/button-spell-slot", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "button",
    classNames: ["container-button-spellSlots", "button-reset"],
    classNameBindings: ["isActive"],
    isActive: false,

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    click: function click() {
      // this.toggleProperty('isActive');
      get(this, 'clickedButtonSpellSlot')(get(this, 'isActive'));
    }
  });
});