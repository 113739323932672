define('spellbinder2/components/ember-do-forms/checkbox-field', ['exports', 'ember-do-forms/components/ember-do-forms/checkbox-field'], function (exports, _checkboxField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _checkboxField.default;
    }
  });
});