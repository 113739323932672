define('spellbinder2/mixins/scrolling', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create({
    bindScrolling: function bindScrolling() {
      var _this = this;

      var onScroll = void 0;

      onScroll = this.debounce(function () {
        return _this.scrolled();
      }, 0);

      // onScroll = () => {
      //   return this.scrolled();
      // };

      var el = get(this, 'element');

      Ember.$(el).bind('touchmove', onScroll);
      Ember.$(el).bind('scroll', onScroll);
    },
    unbindScrolling: function unbindScrolling() {

      var el = get(this, 'element');

      Ember.$(el).unbind('scroll');
      Ember.$(el).unbind('touchmove');
    },
    debounce: function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this,
            args = arguments;
        var later = function later() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }
  });
});