define("spellbinder2/components/button-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: ""
  });
});