define('spellbinder2/controllers/characters/character/spells/spell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias;
  exports.default = Controller.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    atBottomOfPage: false,

    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    spell: alias('model.spell'),
    characterSpells: alias('model.characterSpells'),
    character: alias('model.character'),

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    isOnSpellList: computed('spell', 'characterSpells.[]', function () {
      return get(this, 'characterSpells').isAny('id', get(this, 'spell.id'));
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      didScroll: function didScroll(el) {
        // If at the bottom of the scroll view
        if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
          set(this, 'atBottomOfPage', true);
        } else {
          set(this, 'atBottomOfPage', false);
        }
      },
      clickedAddSpell: function clickedAddSpell() {
        var character = get(this, 'character');
        var spells = get(character, 'spells');
        var spell = get(this, 'spell');

        spells.addObject(spell);

        character.save();
      },
      clickedRemoveSpell: function clickedRemoveSpell() {
        var character = get(this, 'character');
        var spells = get(character, 'spells');
        var spell = get(this, 'spell');

        var oldSpell = spells.findBy('id', get(spell, 'id'));

        spells.removeObject(oldSpell);

        character.save();
      }
    }
  });
});