define('spellbinder2/components/container-list-spells', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      _Ember$computed = Ember.computed,
      filter = _Ember$computed.filter,
      union = _Ember$computed.union,
      intersect = _Ember$computed.intersect;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    // tagName: "",
    classNames: ["container-listSpells"],
    searchResults: [],
    levelFilters: [],
    classFilter: null,
    schoolFilter: null,

    arraySlice: 30,
    arraySliceMin: 30,
    arraySliceMax: 379,
    arraySliceIncrement: 50,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    // init() {
    //   this._super(...arguments);
    //
    //   set(this, 'arraySlice', 20);
    //   Ember.run.next(this, () => {
    //     // @TODO: This should be dynamically determined.
    //     set(this, 'arraySlice', 379);
    //   });
    // },

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    // 1: Filter By Search Results
    // Note: sort by ID first? Quicker to do index of I think.
    searchFilteredSpells: computed('spells.[]', 'searchResults.[]', function () {
      var spells = get(this, 'spells');
      var searchResults = get(this, 'searchResults');
      var searchFilteredSpells = Ember.A([]);

      if (searchResults === null || searchResults === undefined || get(searchResults, 'length') <= 0) {
        return spells.sortBy('name');
      }

      searchResults.forEach(function (result) {
        searchFilteredSpells.addObject(spells.findBy('id', result));
      });

      return searchFilteredSpells;
    }),

    // 2: Filter By Class Filter
    classFilteredSpells: computed('spells.[]', 'classFilter.value', function () {
      var spells = get(this, 'spells');
      var classFilter = get(this, 'classFilter');

      if (classFilter === null || classFilter === undefined) {
        return spells.sortBy('name');
      }

      return spells.filter(function (spell) {
        return get(spell, 'classes').any(function (spellClass) {
          return spellClass === classFilter.value;
        });
      });
    }),

    // 3: Filter By School Filter
    schoolFilteredSpells: computed('spells.[]', 'schoolFilter.value', function () {
      var spells = get(this, 'spells');
      var schoolFilter = get(this, 'schoolFilter');

      if (schoolFilter === null || schoolFilter === undefined) {
        return spells.sortBy('name');
      }

      return spells.filterBy('school', schoolFilter.value);
    }),

    // 4: Filter By Level
    levelFilterdSpells: computed('spells.[]', 'levelFilters.@each.isActive', function () {
      var spells = get(this, 'spells');
      var levelFilters = get(this, 'levelFilters');

      if (levelFilters === null || levelFilters === undefined || get(levelFilters, 'length') <= 0 || !levelFilters.isAny('isActive', true)) {
        return spells.sortBy('name');
      }

      var levelFilterdSpells = Ember.A([]);

      levelFilters.forEach(function (level) {
        if (level.isActive) {
          var spellsAtThisLevel = spells.filter(function (spell) {
            if (level.name === 0) {
              return get(spell, 'level') === 'cantrip';
            } else {
              return get(spell, 'level') === '' + level.name;
            }
          });
          levelFilterdSpells.addObjects(spellsAtThisLevel);
        }
      });

      return levelFilterdSpells.uniq();
    }),

    // filteredSpells: intersect('searchFilteredSpells.@each.id', 'classFilteredSpells.@each.id', 'schoolFilteredSpells.@each.id', 'levelFilterdSpells.@each.id'),

    filteredSpells: computed('searchFilteredSpells.[]', 'classFilteredSpells.[]', 'schoolFilteredSpells.[]', 'levelFilterdSpells.[]', function () {
      var sfs = get(this, 'searchFilteredSpells');
      var cfs = get(this, 'classFilteredSpells');
      var scfc = get(this, 'schoolFilteredSpells');
      var lfc = get(this, 'levelFilterdSpells');

      var i1 = this.intersect(sfs, cfs);
      var i2 = this.intersect(scfc, lfc);

      // return this.intersect(i1, i2).sortBy('name');
      return this.intersect(i1, i2);
    }),

    filteredCount: computed('filteredSpells.[]', function () {
      return get(this, 'filteredSpells.length');
    }),

    slicedSpells: computed('filteredSpells.[]', 'arraySlice', function () {
      var i = get(this, 'arraySlice');
      return get(this, 'filteredSpells').slice(0, i);
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      updateList: function updateList() {
        var s = get(this, 'arraySlice');
        var max = get(this, 'arraySliceMax');
        var i = get(this, 'arraySliceIncrement');
        if (s + i < max) {
          set(this, 'arraySlice', s + i);
        } else {
          set(this, 'arraySlice', max);
        }
      },
      updatedSearchResults: function updatedSearchResults(results) {
        var _this = this;

        Ember.run.next(this, function () {
          set(_this, 'arraySlice', get(_this, 'arraySliceMin'));
          set(_this, 'searchResults', results);
        });
      },
      updatedLevels: function updatedLevels(levelFilters) {
        set(this, 'arraySlice', get(this, 'arraySliceMin'));
        set(this, 'levelFilters', levelFilters);
      },
      updatedClassValue: function updatedClassValue(classFilter) {
        set(this, 'arraySlice', get(this, 'arraySliceMin'));
        set(this, 'classFilter', classFilter);
      },
      updatedSchoolValue: function updatedSchoolValue(schoolFilter) {
        set(this, 'arraySlice', get(this, 'arraySliceMin'));
        set(this, 'schoolFilter', schoolFilter);
      },
      clickedClearFilters: function clickedClearFilters() {
        set(this, 'arraySlice', get(this, 'arraySliceMin'));
        set(this, 'filteredSpells', get(this, 'spells'));
        set(this, 'searchResults', null);
        set(this, 'levelFilters', null);
        set(this, 'classFilter', null);
        set(this, 'schoolFilter', null);
      }
    },

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    intersect: function intersect(a, b) {
      return a.filter(function (n) {
        return b.includes(n);
      });
    }
  });
});