define('spellbinder2/components/input-search', ['exports', 'npm:fuse.js', 'ember-keyboard'], function (exports, _npmFuse, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-input-search"],
    classNameBindings: ["isActive"],
    value: "",

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);

      this.options = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: "name",
          weight: 0.7
        }, {
          name: "description",
          weight: 0.3
        }],
        id: "id",
        verbose: false
      };

      var list = get(this, 'convertedList');
      this.fuse = new _npmFuse.default(list, this.options);

      this.get('EventBus').subscribe('clearSearchInput', this, 'onClearSearchInput');

      set(this, 'keyboardPriority', 1);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('EventBus').unsubscribe('clearSearchInput');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      // Ember.run.next(this, () => {
      //   const list = get(this, 'convertedList');
      //   this.fuse = new Fuse(list, this.options);
      //   let result = this.fuse.search(get(this, 'value'));
      //   get(this, 'updatedSearchResults')(result);
      // });

      var search = this.debounce(function () {
        var list = get(_this, 'convertedList');
        _this.fuse = new _npmFuse.default(list, _this.options);
        var result = _this.fuse.search(get(_this, 'value'));
        get(_this, 'updatedSearchResults')(result);
      }, 500);

      search();
    },


    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    convertedList: computed('list.[]', function () {
      var l = get(this, 'list');

      if (l === null || l === undefined) {
        return null;
      }

      return l.map(function (spell) {
        return spell.toJSON({ includeId: true });
      });
    }),

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    onEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function (event) {
      get(this, 'updatedSearchValue')("");
    }),

    debounce: function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this,
            args = arguments;
        var later = function later() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    onClearSearchInput: function onClearSearchInput() {
      set(this, 'value', "");
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      updatedValue: function updatedValue(value) {
        var _this2 = this;

        set(this, 'value', value);

        var search = this.debounce(function () {
          var result = _this2.fuse.search(value);
          get(_this2, 'updatedSearchValue')(value);
          get(_this2, 'updatedSearchResults')(result);
        }, 500);

        search();
      },
      onEnter: function onEnter() {
        var result = this.fuse.search(get(this, 'value'));
        get(this, 'updatedSearchResults')(result);
      },
      onEscape: function onEscape() {
        set(this, 'value', "");
        var result = this.fuse.search(get(this, 'value'));
        get(this, 'updatedSearchResults')(result);
      },
      onFocusIn: function onFocusIn() {
        set(this, 'keyboardActivated', true);
      },
      onFocusOut: function onFocusOut() {
        set(this, 'keyboardActivated', false);
      }
    }
  });
});