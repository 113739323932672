define('spellbinder2/adapters/spell', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      return '/spells.json';
    },
    urlForQuery: function urlForQuery(modelName, snapshot) {
      return '/spells-initial.json';
    },
    urlForFindRecord: function urlForFindRecord(id) {
      // return `/spells/spell${id}.json`;
      return '/spells/' + id + '.json';
    }
  });
});