define('spellbinder2/components/modal-settings', ['exports', 'spellbinder2/components/modal-base'], function (exports, _modalBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = _modalBase.default.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    session: inject.service(''),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-modal-settings"],

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, 'isActive')) {
        set(this, 'keyboardActivated', true);
      } else {
        set(this, 'keyboardActivated', false);
      }
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      signOut: function signOut() {
        get(this, 'signOut')();
      }
    }
  });
});