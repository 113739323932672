define('spellbinder2/components/button-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    router: inject.service(''),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-buttonHeader"],
    classNameBindings: ["backgroundColor", "isActive", "containerClass", "isCondensed"],

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    isCondensed: computed('router.currentRouteName', 'media.isMobile', function () {
      return get(this, 'media.isMobile') && get(this, 'router.currentRouteName') === 'characters.character.spells.spell';
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedSlots: function clickedSlots() {
        get(this, 'clickedSlots')();
      },
      clickedUser: function clickedUser() {
        get(this, 'clickedUser')();
      }
    }
  });
});