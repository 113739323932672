define('spellbinder2/components/form-rename-character', ['exports', 'spellbinder2/validations/createCharacter', 'ember-changeset-validations', 'ember-changeset'], function (exports, _createCharacter, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    store: inject.service(''),
    session: inject.service(''),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-form-createCharacter", "container-form-modal"],

    form: {
      characterName: ""
    },

    CreateCharacterValidations: _createCharacter.default,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      var form = get(this, 'form');
      this.changeset = new _emberChangeset.default(form, (0, _emberChangesetValidations.default)(_createCharacter.default), _createCharacter.default);
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      formSubmitted: function formSubmitted(changeset) {
        var _this = this;

        set(this, 'hasSubmitted', true);
        set(this, 'isLoading', true);

        changeset.validate();

        if (get(changeset, 'isValid')) {
          changeset.save();

          return this._submitted(changeset).then(function (newCharacter) {
            set(_this, 'isLoading', false);
            get(_this, 'formSubmitted')(newCharacter);
          });
        } else {
          set(this, 'isLoading', false);
        }
      },
      clickedCancelRenameCharacter: function clickedCancelRenameCharacter() {
        get(this, 'clickedCancelRenameCharacter')();
      }
    },

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    _submitted: function _submitted(changeset) {
      var c = get(this, 'character');

      console.log(changeset);

      set(c, 'name', get(changeset, 'characterName'));

      return c.save().catch(function (error) {
        console.error(error);
      });
    }
  });
});