define('spellbinder2/components/modal-create-character', ['exports', 'spellbinder2/components/modal-base'], function (exports, _modalBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = _modalBase.default.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-modal-createCharacter"]
  });
});