define("spellbinder2/components/button-add-remove-spell", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "button",
    classNames: ["container-button-addRemoveSpell", "button-reset"],
    classNameBindings: ["isOnSpellList:is-remove", "isActive"],

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    click: function click() {
      if (get(this, 'isOnSpellList')) {
        get(this, 'clickedRemoveSpell')();
      } else {
        get(this, 'clickedAddSpell')();
      }
    }
  });
});