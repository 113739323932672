define("spellbinder2/components/filters-spells", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-filtersSpells"],
    classNameBindings: ["isVisible"],

    classes: [{
      name: "Bard",
      value: "bard"
    }, {
      name: "Cleric",
      value: "cleric"
    }, {
      name: "Cleric (Trickery)",
      value: "cleric (trickery)"
    }, {
      name: "Druid",
      value: "druid"
    }, {
      name: "Paladin",
      value: "paladin"
    }, {
      name: "Ranger",
      value: "ranger"
    }, {
      name: "Sorcerer",
      value: "sorcerer"
    }, {
      name: "Warlock",
      value: "warlock"
    }, {
      name: "Wizard",
      value: "wizard"
    }],

    schools: [{
      name: "Abjuration",
      value: "abjuration"
    }, {
      name: "Conjuration",
      value: "conjuration"
    }, {
      name: "Divination",
      value: "divination"
    }, {
      name: "Enchantment",
      value: "enchantment"
    }, {
      name: "Evocation",
      value: "evocation"
    }, {
      name: "Illusion",
      value: "illusion"
    }, {
      name: "Necromancy",
      value: "necromancy"
    }, {
      name: "Transmutation",
      value: "transmutation"
    }, {
      name: "Universal",
      value: "universal"
    }],

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {

      // updatedLevels() {
      //   get(this, 'updatedFilters')(get(this, 'levels'), get(this, 'selectedClass'), get(this, 'selectedSchool'));
      // },
      //
      // updatedClassValue(value) {
      //   set(this, 'selectedClass', value);
      //   get(this, 'updatedFilters')(get(this, 'levels'), get(this, 'selectedClass'), get(this, 'selectedSchool'));
      // },
      //
      // updatedSchoolValue(value) {
      //   set(this, 'selectedSchool', value);
      //   get(this, 'updatedFilters')(get(this, 'levels'), get(this, 'selectedClass'), get(this, 'selectedSchool'));
      // }

      updatedLevels: function updatedLevels() {
        get(this, 'updatedLevels')(get(this, 'levels'));
      },
      updatedClassValue: function updatedClassValue(value) {
        set(this, 'selectedClass', value);
        get(this, 'updatedClassValue')(get(this, 'selectedClass'));
      },
      updatedSchoolValue: function updatedSchoolValue(value) {
        set(this, 'selectedSchool', value);
        get(this, 'updatedSchoolValue')(get(this, 'selectedSchool'));
      }
    }
  });
});