define("spellbinder2/components/button-clear-filters", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "button",
    classNames: ["container-button-clearFilters", "button", "outline-yellowMedium", "text-yellowMedium"],
    classNameBindings: ["isActive"],

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    click: function click() {
      get(this, 'clickedClearFilters')();
    }
  });
});