define("spellbinder2/components/input-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-input-number"],
    classNameBindings: ["classes", "hasValue"],
    min: 0,
    max: null,

    value: 0,

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedSubtractNumber: function clickedSubtractNumber() {
        if (get(this, 'value') !== get(this, 'min')) {
          this.decrementProperty('value');
          get(this, 'updatedValue')(get(this, 'value'));
        }
      },
      clickedAddNumber: function clickedAddNumber() {
        if (get(this, 'value') !== get(this, 'max')) {
          this.incrementProperty('value');
          get(this, 'updatedValue')(get(this, 'value'));
        }
      }
    }
  });
});