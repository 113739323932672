define('spellbinder2/components/do-fields', ['exports', 'ember-do-forms/components/do-fields'], function (exports, _doFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _doFields.default;
    }
  });
});