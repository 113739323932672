define('spellbinder2/models/spell', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      get = _Ember.get,
      computed = _Ember.computed;
  exports.default = _emberData.default.Model.extend({
    casting_time: _emberData.default.attr('string'),
    classes: _emberData.default.attr(),
    components: _emberData.default.attr(),
    description: _emberData.default.attr('string'),
    duration: _emberData.default.attr('string'),
    higher_levels: _emberData.default.attr('string'),
    level: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    range: _emberData.default.attr('string'),
    ritual: _emberData.default.attr('boolean'),
    school: _emberData.default.attr('string'),
    tags: _emberData.default.attr(),
    type: _emberData.default.attr('string'),

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    componentsParsed: computed('components.verbal', 'components.somatic', 'components.material', function () {
      var v = get(this, 'components.verbal');
      var s = get(this, 'components.somatic');
      var m = get(this, 'components.material');

      var c = "";

      if (v) {
        c = 'V';
      }

      if (s) {
        if (v) {
          c = c + ', S';
        } else {
          c = 'S';
        }
      }

      if (m) {
        if (v || s) {
          c = c + ', M';
        } else {
          c = 'M';
        }
      }

      return c;
    }),

    materialsParsed: computed('components.material', 'components.materials_needed', function () {

      var m = "";

      if (get(this, 'components.material')) {
        var mn = get(this, 'components.materials_needed');

        mn.forEach(function (material, index) {
          if (index === 0) {
            m = material.charAt(0).toUpperCase() + material.slice(1);
          } else {
            m = m + ', ' + material;
          }
        });
      }

      return m;
    }),

    // descriptionParsed: computed('description', function() {
    //   const d = get(this, 'description');
    //
    //   if (d === null || d === undefined) {
    //     return null;
    //   }
    //
    //   const parts = d.split('\n');
    //
    //   let whole = '';
    //   parts.forEach((part) => {
    //     if (part !== "") {
    //       whole = `${whole}<p class="copy-body copy-description-spell text-blackMedium">${part}</p>`;
    //     }
    //   });
    //   return whole;
    // }),

    // descriptionParsed: computed('description', function() {
    //   const d = get(this, 'description');
    //
    //   if (d === null || d === undefined) {
    //     return null;
    //   }
    //
    //   return d.replace("(../", "(characters/character/spells/");
    // }),

    byline: computed('level', 'school', function () {
      var level = get(this, 'level');
      var school = get(this, 'school');

      if (level === null || level === undefined) {
        return null;
      }

      if (school === null || school === undefined) {
        return null;
      }

      school = this.toTitleCase(school);

      if (this.isNumeric(level)) {
        level = this.ordinal_suffix_of(level);
      } else {
        level = this.toTitleCase(level);
      }

      return level + ' Level ' + school;
    }),

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    toTitleCase: function toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    isNumeric: function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    ordinal_suffix_of: function ordinal_suffix_of(i) {
      var j = i % 10,
          k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    }
  });
});