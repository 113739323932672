define('spellbinder2/components/form-register', ['exports', 'spellbinder2/validations/register', 'ember-changeset-validations', 'ember-changeset'], function (exports, _register, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({

    ////////////////////////////////
    /////////// Services ///////////
    ////////////////////////////////

    firebaseApp: inject.service(''),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-form-login"],

    form: {
      email: "",
      password: "",
      passwordConfirmation: ""
    },

    RegisterValidations: _register.default,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      var form = get(this, 'form');
      this.changeset = new _emberChangeset.default(form, (0, _emberChangesetValidations.default)(_register.default), _register.default);
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      formSubmitted: function formSubmitted(changeset) {
        set(this, 'hasSubmitted', true);
        set(this, 'isLoading', true);

        changeset.validate();

        if (get(changeset, 'isValid')) {
          changeset.save();

          return this._login(get(this, 'form.email'), get(this, 'form.password'));
        } else {
          set(this, 'isLoading', false);
        }
      }
    },

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    _login: function _login(email, password) {
      var _this = this;

      var auth = get(this, 'firebaseApp').auth();

      return auth.createUserWithEmailAndPassword(email, password).then(function () {
        set(_this, 'isLoading', false);
        get(_this, 'formSubmitted')();
      }).catch(function (error) {
        set(_this, 'isLoading', false);

        if (error.message === "Not Valid") {
          return true;
        } else if (_this._isFirebaseError(error)) {
          var e = _this._parseFirebaseError(error);
          _this.get('changeset').pushErrors(e.attribute, e.message);
        } else {

          if (get(_this, 'session.isAuthenticated')) {
            get(_this, 'session').close();
          }

          console.error(error);
          get(_this, 'raven').captureException(error);
        }
      });
    },
    _isFirebaseError: function _isFirebaseError(error) {
      console.log(error);
      return error.code === "auth/email-already-in-use" || error.code === "auth/invalid-email" || error.code === "auth/operation-not-allowed" || error.code === 'auth/weak-password';
    },


    // _isFirebaseError(error) {
    //   return error.code === "auth/email-already-in-use" || error.code === "auth/invalid-email" || error.code === "auth/weak-password" || error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found'
    // },

    _parseFirebaseError: function _parseFirebaseError(error) {
      if (error.code === "auth/email-already-in-use") {
        return {
          attribute: "email",
          message: "This email is already being used."
        };
      } else if (error.code === "auth/invalid-email") {
        return {
          attribute: "email",
          message: "The email is invalid."
        };
      } else if (error.code === "auth/weak-password") {
        return {
          attribute: "password",
          message: "Please provide a stronger password."
        };
      } else {
        return null;
      }
    }
  });
});