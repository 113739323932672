define("spellbinder2/components/filter-level", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      alias = Ember.computed.alias;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-filter-spellLevel"],
    classNameBindings: ["isActive"],

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(this, function () {
        get(_this, 'updatedLevels')();
      });
    },


    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    isActive: alias('level.isActive'),
    name: alias('level.name'),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    click: function click() {
      this.toggleProperty('isActive');
      get(this, 'updatedLevels')();
    }
  });
});