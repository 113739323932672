define("spellbinder2/components/segment-segmented-filter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      computed = Ember.computed;
  exports.default = Component.extend({

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    tagName: "li",
    classNames: ["container-segment-segmentedFilter"],
    classNameBindings: ["isActive"],

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    isActive: computed('activeSegment', function () {
      return get(this, 'segment.value') === get(this, 'activeSegment');
    }),

    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    click: function click() {
      get(this, 'clickedSegment')(get(this, 'segment'));
    }
  });
});