define("spellbinder2/controllers/characters/character/spells", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      _get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      alias = Ember.computed.alias;
  exports.default = Controller.extend({

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    router: Ember.inject.service(),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    queryParams: ["list"],
    list: "all_spells",

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.get('EventBus').subscribe('clickedReturnToSpells', this, 'onClickedReturnToSpells');
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('EventBus').unsubscribe('clickedReturnToSpells');
    },


    /////////////////////////////////////////
    /////////// One-Line Computed ///////////
    /////////////////////////////////////////

    allSpells: alias('model.allSpells'),
    character: alias('model.character'),
    characterSpells: alias('model.characterSpells'),

    ///////////////////////////////////////////
    /////////// Computed Properties ///////////
    ///////////////////////////////////////////

    spells: computed('list', 'allSpells.[]', 'characterSpells.[]', function () {
      if (_get(this, 'list') === "user_spells") {
        return _get(this, 'characterSpells');
      } else {
        return _get(this, 'allSpells');
      }
    }),

    spellIsSelected: computed('router.currentRouteName', {
      get: function get() {
        return _get(this, 'router.currentRouteName') === 'characters.character.spells.spell';
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    onClickedReturnToSpells: function onClickedReturnToSpells() {
      var _this = this;

      Ember.run.next(this, function () {
        set(_this, 'spellIsSelected', false);
      });
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedSpell: function clickedSpell() {
        set(this, 'spellIsSelected', true);

        if (_get(this, 'media.isMobile')) {
          _get(this, 'EventBus').publish('updateNavButtonState', 'backToSpells');
        }
      },
      clickedSegment: function clickedSegment(segment) {
        set(this, 'list', segment.value);
      }
    }
  });
});