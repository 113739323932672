define('spellbinder2/components/panel-characters', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {

    //////////////////////////////////
    /////////// Injections ///////////
    //////////////////////////////////

    store: inject.service(''),

    ////////////////////////////////////////
    /////////// Plain Properties ///////////
    ////////////////////////////////////////

    classNames: ["container-panel-chooseCharacter"],
    classNameBindings: ["isActive"],
    isEditMode: false,
    showModalConfirmDeleteCharacter: false,
    showModalEditCharacter: false,
    showModalRenameCharacter: false,
    modalConfirmDeleteCharacterIsLoading: false,
    focusedCharacter: null,

    ///////////////////////////////////////
    /////////// Lifecycle Hooks ///////////
    ///////////////////////////////////////

    init: function init() {
      this._super.apply(this, arguments);
      this.get('EventBus').subscribe('clickedCloseEditCharacter', this, 'onClickedCloseEditCharacter');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, 'isActive')) {
        set(this, 'keyboardActivated', true);
      } else {
        set(this, 'keyboardActivated', false);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('EventBus').unsubscribe('clickedCloseEditCharacter');
    },


    ///////////////////////////////
    /////////// Actions ///////////
    ///////////////////////////////

    actions: {
      clickedOverlay: function clickedOverlay() {
        if (get(this, 'clickOverlayToClose')) {
          get(this, 'clickedCloseModal')();
        }
      },
      clickedAddNewCharacter: function clickedAddNewCharacter() {
        get(this, 'clickedAddNewCharacter')();
      },
      clickedEditCharacter: function clickedEditCharacter(character) {
        set(this, 'focusedCharacter', character);
        get(this, 'EventBus').publish('updateNavButtonState', 'closeEditCharacter');
        set(this, 'showModalEditCharacter', true);
      },
      clickedOpenRenameCharacter: function clickedOpenRenameCharacter() {
        set(this, 'showModalRenameCharacter', true);
        get(this, 'EventBus').publish('updateNavButtonState', 'hiddenWhileEditCharacters');
      },
      renameCharacterFormSubmitted: function renameCharacterFormSubmitted() {
        set(this, 'showModalRenameCharacter', false);
        get(this, 'EventBus').publish('updateNavButtonState', 'closeEditCharacter');
      },
      clickedCancelRenameCharacter: function clickedCancelRenameCharacter() {
        set(this, 'showModalRenameCharacter', false);
        get(this, 'EventBus').publish('updateNavButtonState', 'closeEditCharacter');
      },
      clickedOpenChangeImage: function clickedOpenChangeImage() {},
      clickedDeleteCharacter: function clickedDeleteCharacter() {
        set(this, 'showModalConfirmDeleteCharacter', true);
        get(this, 'EventBus').publish('updateNavButtonState', 'hiddenWhileEditCharacters');
      },
      clickedConfirmDelete: function clickedConfirmDelete() {
        var _this = this;

        var user = get(this, 'user');
        var character = get(this, 'focusedCharacter');
        var cid = get(character, 'id');

        set(this, 'modalConfirmDeleteCharacterIsLoading', true);

        character.destroyRecord().then(function () {
          return user.save();
        }).then(function () {
          return get(user, 'characters');
        }).then(function (characters) {

          get(_this, 'store').recordForId('character', cid).unloadRecord();

          set(_this, 'modalConfirmDeleteCharacterIsLoading', false);
          set(_this, 'showModalConfirmDeleteCharacter', false);
          set(_this, 'focusedCharacter', null);
          set(_this, 'showModalEditCharacter', false);
          get(_this, 'deletedCharacter')(character, characters);
        }).catch(function (error) {
          console.error(error);
        });
      },
      clickedCancelDelete: function clickedCancelDelete() {
        set(this, 'showModalConfirmDeleteCharacter', false);
        get(this, 'EventBus').publish('updateNavButtonState', 'closeEditCharacter');
      },
      clickedCloseModalEditCharacter: function clickedCloseModalEditCharacter() {
        set(this, 'showModalEditCharacter', false);
        get(this, 'EventBus').publish('updateNavButtonState', 'openChooseCharacter');
      }
    },

    /////////////////////////////////
    /////////// Functions ///////////
    /////////////////////////////////

    onClickedCloseEditCharacter: function onClickedCloseEditCharacter() {
      set(this, 'showModalEditCharacter', false);
    },


    onEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function (event) {
      if (get(this, 'isActive')) {
        get(this, 'clickedCloseModal')();
      }
    })
  });
});